<template>
    <v-content>
        <v-layout ma-6>
            <v-flex xs12 px-12>
                <v-layout>
                    <v-flex xs12 px-12 pt-12 style="text-align: center;color: #3E7662;font-weight: bold;font-size: 36px">
                       <span>แผนที่</span>
                    </v-flex>
                </v-layout>
                <v-layout justify-center>
                    <v-flex xs10 pb-12>
                        <v-layout>
                            <v-flex xs12 px-12 style="text-align: center;">
                                <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1726.1904652094547!2d100.53016709957899!3d13.768828183782722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa916f22e6993b283!2z4Lin4Li04LiX4Lii4Liy4Lil4Lix4Lii4LmB4Lie4LiX4Lii4Lio4Liy4Liq4LiV4Lij4LmM4Lie4Lij4Liw4Lih4LiH4LiB4Li44LiO4LmA4LiB4Lil4LmJ4Liy!5e0!3m2!1sth!2sth!4v1576720549722!5m2!1sth!2sth"
                                        width="100%"
                                        height="550"
                                        frameborder="0"
                                        style="border:1px;"
                                        allowfullscreen=""
                                >
                                </iframe>
                            </v-flex>
                        </v-layout>
                        <v-layout justify-center>
                            <v-flex xs11 pt-4 pb-8 px-6 mb-6 style="text-align: left;background-color: #3E7662;color: #fff;">
                                <v-layout>
                                    <v-flex style="font-size: 16px">
                                        <span>ติดต่อสอบถาม  <b>สำนักงานพิจารณาโครงการวิจัย กรมแพทย์ทหารบก</b></span><br/>
                                        <span><b>โทร.</b>02-3547600-28 ต่อ 94297 , 94270</span><br/>
                                        <span><b>โทรสาร.</b>02-3549011</span> <span><b>Email.</b>irbrta.ec@gmail.com</span>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>

            </v-flex>
        </v-layout>
    </v-content>
</template>

<script>
    export default {
        name: "ContactUs"
    }
</script>

<style scoped>

</style>
